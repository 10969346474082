<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Auditoría interna</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarAuditoria)">
                            <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark" active-tab-class="">
                                <b-tab title="Datos generales" active>

                                    <b-row>
                                        <b-col md="4">
                                            <validation-provider name="empresa" :rules="{required: true,max:499}" v-slot="validationContext">
                                                <b-form-group label="Empresa:" class="mb-2">
                                                    <b-form-input v-model="datosAuditoria.empresa" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese la empresa"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="lugar de la Auditoría (dirección/es)" :rules="{required: true,max:499}" v-slot="validationContext">
                                                <b-form-group label="Lugar de la Auditoría (dirección/es):" class="mb-2">
                                                    <b-form-input v-model="datosAuditoria.lugarAuditoria" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el lugar de auditoría"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="fecha auditoría" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha auditoría:" class="mb-2">
                                                    <b-form-input v-model="datosAuditoria.fechaAuditoria" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="alcance" :rules="{required: true,max:499}" v-slot="validationContext">
                                                <b-form-group label="Alcance:" class="mb-2">
                                                    <b-form-input v-model="datosAuditoria.alcance" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el alcance"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="auditor jefe" :rules="{required: true,max:499}" v-slot="validationContext">
                                                <b-form-group label="Auditor jefe:" class="mb-2">
                                                    <b-form-input v-model="datosAuditoria.auditorJefe" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el auditor jefe"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="auditor"  :rules="{max:499}" v-slot="validationContext">
                                                <b-form-group label="Auditor:" class="mb-2">
                                                    <b-form-input v-model="datosAuditoria.auditor" type="text" :state="getValidationState(validationContext)" placeholder="Ingrese el auditor"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="norma" rules="required" v-slot="{errors}">
                                                <b-form-group label="Norma:" class="mb-2">
                                                    <v-select :loading="loadingVselect" label="nombre" @input="cargarDatosNorma" :reduce="comboNormas => comboNormas.idNorma" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosAuditoria.norma , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosAuditoria.norma" :options="comboNormas" @search:blur="blurNormas">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Normas'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="8"></b-col>
                                        <b-col md="1"></b-col>
                                        <b-col md="10">
                                            <p class="mt-3">Por favor, complete a continuación el siguiente CUESTIONARIO, teniendo en cuenta que en cada apartado auditado podrá seleccionar entre:</p>
                                            <ul>
                                                <li><strong>OK:</strong> Apartado correcto. Vd. ha verificado, mediante evidencias, que la empresa está llevando a cabo dicho apartado de forma correcta.</li>
                                                <li><strong>DESVIACION:</strong> Apartado incorrecto. Existen evidencias que demuestran el incumplimiento de dicho apartado o no existen evidencias suficiente que justifiquen su validación. La empresa deberá llevar a cabo acciones correctivas.</li>
                                                <li><strong>ODM:</strong> Observación de mejora. Apartado correcto en el que Vd. Ha identificado posibles mejoras de procesos en la empresa Auditada.</li>
                                                <li><strong>NA:</strong> No aplica.</li>
                                            </ul>

                                            <p>Puede realizar la auditoria en diferentes días y momentos; para ello, vaya guardando la información mediante el botón Guardar Formulario y, una vez finalizada, proceda a Imprimir Informe para generar el mismo y entregarlo a su cliente.</p>
                                        </b-col>
                                        <b-col md="1"></b-col>
                                    </b-row>
                                </b-tab>

                                <template>
                                    <b-tab v-for="(pestana, k) in listaPestana" :key="k" :title="`${pestana.numero}. ${pestana.texto}`">
                                        <b-table-simple bordered class="table-custom " mediun responsive outlined fixed hover>
                                            <b-thead>
                                                <b-th width="50%" class="text-center">
                                                    Apartado analizado
                                                </b-th>
                                                <b-th width="15%" class="text-center">
                                                    Resultado
                                                </b-th>
                                                <b-th width="35%" class="text-center">
                                                    Observaciones, comentarios y evidencias
                                                </b-th>
                                            </b-thead>

                                            <b-tbody v-for="(punto, j) in listaPestana[k].puntos" :key="j">
                                                <b-tr>
                                                    <b-td colspan="3" class="py-1">
                                                        <span class="h6 text-muted">{{`${pestana.numero}.${punto.numero}. ${(punto.texto).toUpperCase()}`}}</span>
                                                    </b-td>
                                                </b-tr>

                                                <b-tr v-for="(pregunta, v) in listaPestana[k].puntos[j].preguntas" :key="v">
                                                    <b-td>
                                                        <p>{{pregunta.texto}}</p>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-select :options="comboResultado" v-model="pregunta.respuesta"></b-form-select>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea placeholder="Ingrese observaciones, comentarios y evidencias" rows="4" v-model="pregunta.observaciones" max-rows="6"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>

                                        </b-table-simple>
                                        <!-- <b-list-group >
                                        <b-list-group-item>
                                            <b-row align-v="center">
                                                <b-col class="align-center text-center">cd
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                           </b-list-group>
                                    -->
                                    </b-tab>
                                </template>
                            </b-tabs>

                            <b-row class="justify-content-center">
                                <b-col class="my-2 text-center" md="2">
                                    <b-button :disabled="disabled" :to="{name: 'Auditoría interna'}" class=" mr-2" variant="dark" @click="modalAnadirEquipo = false">
                                        <i class="fas fa-arrow-left mr-1"></i> Volver
                                    </b-button>
                                </b-col>

                                <b-col v-if="checkPermissions('010-MEJ-AUD','c')==1 && !$route.params.id" class="my-2 text-center" md="2">
                                    <b-button :disabled="disabled" class="mr-2" variant="blue" type="submit">
                                        <i class="fas fa-save mr-1"></i> Guardar formulario
                                    </b-button>
                                </b-col>

                                <b-col class="my-2 text-center" md="2" v-if="datosAuditoria.isFinalizado != 2 && checkPermissions('010-MEJ-AUD','c')==1 && !$route.params.id">
                                    <b-button :disabled="disabled" @click="validarRegistro='ok'" variant="success" type="submit">
                                        <i class="fas fa-download mr-1"></i> Registrar auditoría
                                    </b-button>
                                </b-col>
                                <!-- edit -->
                                <b-col v-if="checkPermissions('010-MEJ-AUD','u')==1 && $route.params.id" class="my-2 text-center" md="2">
                                    <b-button :disabled="disabled" class="mr-2" variant="blue" type="submit">
                                        <i class="fas fa-save mr-1"></i> Guardar formulario
                                    </b-button>
                                </b-col>

                                <b-col class="my-2 text-center" md="2" v-if="datosAuditoria.isFinalizado != 2 && checkPermissions('010-MEJ-AUD','u')==1 && $route.params.id">
                                    <b-button :disabled="disabled" @click="validarRegistro='ok'" variant="success" type="submit">
                                        <i class="fas fa-download mr-1"></i> Registrar auditoría
                                    </b-button>
                                </b-col>
                            </b-row>

                        </b-form>
                    </validation-observer>

                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            disabled: false,
            loadingVselect: true,

            listaPestana: [],

            comboNormas: [],
            datosAuditoria: {
                idAuditoriaInterna: null,
                idCliente: null,
                empresa: '',
                lugarAuditoria: '',
                fechaAuditoria: moment().format('YYYY-MM-DD'),
                alcance: '',
                auditorJefe: '',
                auditor: '',
                norma: '',
                isFinalizado: 1,
            },
            comboResultado: [{
                value: 1,
                text: 'OK'
            }, {
                value: 2,
                text: 'NA'
            }, {
                value: 3,
                text: 'DESVIACIÓN'
            }, {
                value: 4,
                text: 'ODM'
            }],
            validarRegistro: '',

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        registrarAuditoria() {
            let me = this;
            me.disabled = true;
 
            if(me.validarRegistro=='ok'){
                me.datosAuditoria.isFinalizado=2;
            }
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-auditoria-interna", {
                        datosAuditoria: me.datosAuditoria,
                        listaPestana: me.listaPestana
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'Auditoría interna'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        blurNormas() {
            this.computedForm.refs.norma.validate();
        },
        cargarDatosNorma() {
            this.loadingVselect = true;
            this.listaPestana = [];
            this.obtenerPestanas();
        },
        obtenerAuditoria() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-auditoria-interna", {
                        params: {
                            idAuditoriaInterna: me.$route.params.id,
                            idCliente: me.datosAuditoria.idCliente
                        },
                    }
                )
                .then(function (response) {

                    me.datosAuditoria.idAuditoriaInterna = response.data[0].idAuditoriaInterna;
                    me.datosAuditoria.idCliente = response.data[0].idCliente;
                    me.datosAuditoria.empresa = response.data[0].empresa;
                    me.datosAuditoria.lugarAuditoria = response.data[0].lugar;
                    me.datosAuditoria.fechaAuditoria = response.data[0].fecha;
                    me.datosAuditoria.alcance = response.data[0].alcance;
                    me.datosAuditoria.auditorJefe = response.data[0].auditorJefe;
                    me.datosAuditoria.auditor = response.data[0].auditor;
                    me.datosAuditoria.norma = response.data[0].idNorma;
                    me.datosAuditoria.isFinalizado = response.data[0].isFinalizado;
                    me.cargarDatosNorma();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                });
        },

        obtenerPestanas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-pestanas-norma", {
                        params: {
                            idNorma: me.datosAuditoria.norma
                        },
                    }
                )
                .then(function (response) {
                    for (let k in response.data) {
                        me.listaPestana.push({
                            idNormaPestana: response.data[k].idNormaPestana,
                            idNorma: response.data[k].idNorma,
                            numero: response.data[k].numero,
                            texto: response.data[k].texto,
                            puntos: [],
                        })
                    }
                    me.obtenerPuntos();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.listaPestana = [];
                    me.loadingVselect = false;
                });
        },
        obtenerPuntos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-puntos-norma", {
                        params: {
                            idNorma: me.datosAuditoria.norma
                        },
                    }
                )
                .then(function (response) {
                    for (let k in me.listaPestana) {
                        for (let j in response.data) {
                            if (me.listaPestana[k].idNormaPestana == response.data[j].idNormaPestana) {
                                me.listaPestana[k].puntos.push({
                                    idNormaPunto: response.data[j].idNormaPunto,
                                    idNormaPestana: response.data[j].idNormaPestana,
                                    numero: response.data[j].numero,
                                    texto: response.data[j].texto,
                                    preguntas: []
                                })
                            }
                        }
                    }
                    if (me.$route.params.id) {
                        me.obtenerPreguntasActualizar();
                    } else if (!me.$route.params.id) {
                        me.obtenerPreguntas();
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.listaPestana = [];
                    me.loadingVselect = false;
                });
        },
        obtenerPreguntas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-norma", {
                        params: {
                            idNorma: me.datosAuditoria.norma
                        },
                    }
                )
                .then(function (response) {
                    for (let k in me.listaPestana) {
                        for (let j in me.listaPestana[k].puntos) {
                            for (let v in response.data) {
                                if (me.listaPestana[k].puntos[j].idNormaPunto == response.data[v].idNormaPunto) {
                                    me.listaPestana[k].puntos[j].preguntas.push({
                                        idAuditoriaInternaDetalle: null,
                                        idNormaPregunta: response.data[v].idNormaPregunta,
                                        idNormaPunto: response.data[v].idNormaPunto,
                                        numero: response.data[v].numero,
                                        texto: response.data[v].texto,
                                        respuesta: 1,
                                        observaciones: ''
                                    })
                                }
                            }
                        }
                    }
                    me.loadingVselect = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.listaPestana = [];
                    me.loadingVselect = false;
                });
        },
        obtenerPreguntasActualizar() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-auditoria-interna", {
                        params: {
                            idNorma: me.datosAuditoria.norma,
                            idAuditoriaInterna: me.datosAuditoria.idAuditoriaInterna
                        },
                    }
                )
                .then(function (response) {
                    for (let k in me.listaPestana) {
                        for (let j in me.listaPestana[k].puntos) {
                            for (let v in response.data) {
                                if (me.listaPestana[k].puntos[j].idNormaPunto == response.data[v].idNormaPunto) {
                                    me.listaPestana[k].puntos[j].preguntas.push({
                                        idAuditoriaInternaDetalle: response.data[v].idAuditoriaInternaDetalle,
                                        idNormaPregunta: response.data[v].idNormaPregunta,
                                        idNormaPunto: response.data[v].idNormaPunto,
                                        numero: response.data[v].numero,
                                        texto: response.data[v].texto,
                                        respuesta: response.data[v].resultado,
                                        observaciones: response.data[v].observaciones,
                                    })
                                }
                            }
                        }
                    }
                    me.loadingVselect = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.listaPestana = [];
                    me.loadingVselect = false;
                });
        },

        listarNormas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-normas", {
                        params: {
                            idCliente: me.datosAuditoria.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboNormas = response.data;
                    me.loadingVselect = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosAuditoria.idCliente = user.uidClient;
            this.listarNormas();
            if (this.$route.params.id) {
                this.obtenerAuditoria();
            }
        }
    }

}
</script>
